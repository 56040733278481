// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-collaboration-js": () => import("./../../../src/templates/archive-collaboration.js" /* webpackChunkName: "component---src-templates-archive-collaboration-js" */),
  "component---src-templates-archive-crops-js": () => import("./../../../src/templates/archive-crops.js" /* webpackChunkName: "component---src-templates-archive-crops-js" */),
  "component---src-templates-archive-members-js": () => import("./../../../src/templates/archive-members.js" /* webpackChunkName: "component---src-templates-archive-members-js" */),
  "component---src-templates-archive-news-js": () => import("./../../../src/templates/archive-news.js" /* webpackChunkName: "component---src-templates-archive-news-js" */),
  "component---src-templates-archive-people-js": () => import("./../../../src/templates/archive-people.js" /* webpackChunkName: "component---src-templates-archive-people-js" */),
  "component---src-templates-archive-topics-js": () => import("./../../../src/templates/archive-topics.js" /* webpackChunkName: "component---src-templates-archive-topics-js" */),
  "component---src-templates-archive-vbs-js": () => import("./../../../src/templates/archive-vbs.js" /* webpackChunkName: "component---src-templates-archive-vbs-js" */),
  "component---src-templates-archive-videos-js": () => import("./../../../src/templates/archive-videos.js" /* webpackChunkName: "component---src-templates-archive-videos-js" */),
  "component---src-templates-collaboration-js": () => import("./../../../src/templates/collaboration.js" /* webpackChunkName: "component---src-templates-collaboration-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-crops-js": () => import("./../../../src/templates/crops.js" /* webpackChunkName: "component---src-templates-crops-js" */),
  "component---src-templates-database-main-page-js": () => import("./../../../src/templates/database-main-page.js" /* webpackChunkName: "component---src-templates-database-main-page-js" */),
  "component---src-templates-database-page-js": () => import("./../../../src/templates/database-page.js" /* webpackChunkName: "component---src-templates-database-page-js" */),
  "component---src-templates-ethics-js": () => import("./../../../src/templates/ethics.js" /* webpackChunkName: "component---src-templates-ethics-js" */),
  "component---src-templates-euroseeds-js": () => import("./../../../src/templates/euroseeds.js" /* webpackChunkName: "component---src-templates-euroseeds-js" */),
  "component---src-templates-frontpage-js": () => import("./../../../src/templates/frontpage.js" /* webpackChunkName: "component---src-templates-frontpage-js" */),
  "component---src-templates-media-kit-js": () => import("./../../../src/templates/media-kit.js" /* webpackChunkName: "component---src-templates-media-kit-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-subjects-js": () => import("./../../../src/templates/subjects.js" /* webpackChunkName: "component---src-templates-subjects-js" */),
  "component---src-templates-topics-js": () => import("./../../../src/templates/topics.js" /* webpackChunkName: "component---src-templates-topics-js" */),
  "component---src-templates-vbs-js": () => import("./../../../src/templates/vbs.js" /* webpackChunkName: "component---src-templates-vbs-js" */)
}

